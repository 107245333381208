<template>
  <div class="contents detailWrap">
    <div class="title flexB">
      <h2>농장정보</h2>
    </div>
    <div class="box one">
      <div class="flexL">
        <p class="bold">농가현황</p>
        <div class="flex">
          <p>등록일</p>
          <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
        <div class="flex" v-if="updatedAt">
          <p>업데이트일</p>
          <span>{{ moment(updatedAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
        <div class="flex">
          <p>농장주</p>
          <span>{{ farmer ? farmer.username : "" }}</span>
        </div>
        <div class="flex">
          <p>농장명 <span>*</span></p>
          <input type="text" v-model="farmName" />
          <span v-if="mainFarm" class="mainFlag">대표</span>
        </div>
        <div class="flex">
          <p>주소 <span>*</span></p>
          <input type="text" v-model="address" />
        </div>
        <div class="flex">
          <p>농장주 연령</p>
          <el-date-picker v-model="ages" type="date" placeholder="선택"> </el-date-picker>
        </div>
        <div class="flex">
          <p>농장주 경력</p>
          <input type="text" v-model="career" />
        </div>
        <div class="flex">
          <p>재배면적(㎡) <span>*</span></p>
          <input type="text" v-model="area" />
        </div>
        <div class="flex">
          <p>재배품목 <span>*</span></p>
          <el-select v-model="crops">
            <el-option value="딸기" label="딸기"></el-option>
            <el-option value="토마토" label="토마토"></el-option>
            <el-option value="사과" label="사과"></el-option>
            <el-option value="고추" label="고추"></el-option>
            <el-option value="포도" label="포도"></el-option>
            <el-option value="레드향" label="레드향"></el-option>
            <el-option value="상추" label="상추"></el-option>
          </el-select>
        </div>
        <div class="flex">
          <p>수확일자 <span>*</span></p>
          <el-date-picker v-model="hvstDay" type="date" placeholder="선택"> </el-date-picker>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">환경제어 프로그램 현황</p>
        <div class="flex">
          <p>PC운영체계</p>
          <input type="text" v-model="os" />
        </div>
        <div class="flex">
          <p>모니터(인치)</p>
          <input type="text" v-model="monitorSize" />
        </div>
        <div class="flex">
          <p>환경제어 프로그램명</p>
          <input type="text" v-model="envrnCtrlProgram" />
        </div>
        <div class="flex">
          <p>PC 설치위치</p>
          <input type="text" v-model="pcLocation" />
        </div>
        <div class="flex">
          <p>UPS 설치여부</p>
          <el-switch v-model="ups"> </el-switch>
        </div>
        <div class="flex">
          <p>인터넷 설치여부</p>
          <el-switch v-model="internet"> </el-switch>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">시설현황</p>
        <div class="flex">
          <p>하우스 유형</p>
          <el-checkbox-group v-model="houseType">
            <el-checkbox label="단동" value="단동">단동</el-checkbox>
            <el-checkbox label="연동" value="연동">연동</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex">
          <p>하우스 설치방향</p>
          <el-radio v-model="houseDirection" label="동서">동서</el-radio>
          <el-radio v-model="houseDirection" label="남북">남북</el-radio>
        </div>
        <div class="flex">
          <p>하우스 피복필름 종류</p>
          <el-radio v-model="houseCover" label="PO">PO</el-radio>
          <el-radio v-model="houseCover" label="PE">PE</el-radio>
        </div>
        <div class="flex">
          <p>하우스 파이프 길이(m)</p>
          <input type="text" v-model="housePipeLength" />
        </div>
        <div class="flex">
          <p>하우스 높이(m)</p>
          <input type="text" v-model="houseHeight" />
        </div>
        <div class="flex">
          <p>하우스 폭(m)</p>
          <input type="text" v-model="houseWidth" />
        </div>
        <div class="flex">
          <p>하우스 길이(m)</p>
          <input type="text" v-model="houseLength" />
        </div>
        <div class="flex">
          <p>보온자재 종류</p>
          <input type="text" v-model="houseInsulation" />
        </div>
        <div class="flex">
          <p>난방방법</p>
          <el-radio v-model="houseHeating" label="열풍">열풍</el-radio>
          <el-radio v-model="houseHeating" label="수막">수막</el-radio>
        </div>
        <div class="flex">
          <p>환기방법</p>
          <el-checkbox-group v-model="houseVentilation">
            <el-checkbox label="출입구"></el-checkbox>
            <el-checkbox label="측창"></el-checkbox>
            <el-checkbox label="천창"></el-checkbox>
            <el-checkbox label="고정팬"></el-checkbox>
            <el-checkbox label="유동팬"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex">
          <p>관수방법</p>
          <el-radio v-model="houseIrrigation" label="점적">점적</el-radio>
          <el-radio v-model="houseIrrigation" label="스프링클러">스프링클러</el-radio>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">외부측정장치 설치현황</p>
        <div class="flex">
          <p>온도센서</p>
          <el-switch v-model="outsideTempSensor"> </el-switch>
        </div>
        <div class="flex">
          <p>풍향센서</p>
          <el-switch v-model="windDirectionSensor"> </el-switch>
        </div>
        <div class="flex">
          <p>풍속센서</p>
          <el-switch v-model="windSpeedSensor"> </el-switch>
        </div>
        <div class="flex">
          <p>일사량센서</p>
          <el-switch v-model="insolationSensor"> </el-switch>
        </div>
        <div class="flex">
          <p>감우센서</p>
          <el-switch v-model="rainfallSensor"> </el-switch>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">내부측정장치 설치현황</p>
        <div class="flex">
          <p>온도센서</p>
          <el-switch v-model="insideTempSensor"> </el-switch>
        </div>
        <div class="flex">
          <p>습도센서</p>
          <el-switch v-model="humiditySensor"> </el-switch>
        </div>
        <div class="flex">
          <p>CO2센서</p>
          <el-switch v-model="co2Sensor"> </el-switch>
        </div>
        <div class="flex">
          <p>광량센서</p>
          <el-switch v-model="lightSensor"> </el-switch>
        </div>
        <div class="flex">
          <p>지온센서</p>
          <el-switch v-model="soilTempSensor"> </el-switch>
        </div>
      </div>

      <div class="flexL">
        <p class="bold">제어</p>
        <div class="flex">
          <p>일중천장</p>
          <el-switch v-model="ceilingWndw"> </el-switch>
        </div>
        <div class="flex">
          <p>이중천장</p>
          <el-switch v-model="dplcWndw"> </el-switch>
        </div>
        <div class="flex">
          <p>측창</p>
          <el-switch v-model="sideWallWndw"> </el-switch>
        </div>
        <div class="flex">
          <p>천장보온커튼</p>
          <el-switch v-model="cellarsHeatCurtain"> </el-switch>
        </div>
        <div class="flex">
          <p>측면보온커튼</p>
          <el-switch v-model="wallHeatCurtain"> </el-switch>
        </div>
        <div class="flex">
          <p>차광커튼</p>
          <el-switch v-model="shadeCurtain"> </el-switch>
        </div>
        <div class="flex">
          <p>난방기</p>
          <el-switch v-model="heatMhrls"> </el-switch>
        </div>
        <div class="flex">
          <p>CO2공급</p>
          <el-switch v-model="co2Supply"> </el-switch>
        </div>
        <div class="flex">
          <p>광량제어</p>
          <el-switch v-model="lightControl"> </el-switch>
        </div>
        <div class="flex">
          <p>유동팬</p>
          <el-switch v-model="dynmcFan"> </el-switch>
        </div>
        <div class="flex">
          <p>관수/관비 시스템</p>
          <el-switch v-model="irrnFrtlzrSys"> </el-switch>
        </div>
        <div class="flex">
          <p>양액기</p>
          <el-switch v-model="nutrientSlutnSys"> </el-switch>
        </div>
        <div class="flex">
          <p>두상살수장치</p>
          <el-switch v-model="sprinkler"> </el-switch>
        </div>
        <div class="flex">
          <p>훈증기</p>
          <el-switch v-model="fumigator"> </el-switch>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">재배기초</p>
        <div class="flex">
          <p>재배 유형</p>
          <el-checkbox-group v-model="ctvtTY">
            <el-checkbox label="양액재배"></el-checkbox>
            <el-checkbox label="토경재배"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex">
          <p>품종명</p>
          <input type="text" v-model="spcies" />
        </div>
        <div class="flex">
          <p>종묘확보방법(자가/구입)</p>
          <input type="text" v-model="seedling" />
        </div>
        <div class="flex">
          <p>배지종류</p>
          <input type="text" v-model="soilType" />
        </div>
        <div class="flex">
          <p>자가퇴비여부(자가/구입)</p>
          <input type="text" v-model="compost" />
        </div>
        <div class="flex">
          <p>정식일</p>
          <el-date-picker v-model="ptngDt" type="date" placeholder="선택"> </el-date-picker>
        </div>
        <div class="flex">
          <p>수확시작일</p>
          <el-date-picker v-model="hvstBgnde" type="date" placeholder="선택"> </el-date-picker>
        </div>
        <div class="flex">
          <p>수확종료일</p>
          <el-date-picker v-model="hvstEndde" type="date" placeholder="선택"> </el-date-picker>
        </div>
        <div class="flex">
          <p>병해충 방제회수</p>
          <input type="text" v-model="dbyhsCccrrncDgree" />
        </div>
        <div class="flex">
          <p>병해충 발생정도</p>
          <input type="text" v-model="dbyhsPrvnbeRtrvl" />
        </div>
      </div>
      <div class="flexL">
        <p class="bold">토양(근권)환경</p>
        <div class="flex">
          <p>공급양액 PH농도</p>
          <input type="text" v-model="nutrientPH" />
        </div>
        <div class="flex">
          <p>공급양액 EC</p>
          <input type="text" v-model="nutrientEC" />
        </div>
        <div class="flex">
          <p>배지 PH</p>
          <input type="text" v-model="mdimPH" />
        </div>
        <div class="flex">
          <p>배지 EC</p>
          <input type="text" v-model="mdimEC" />
        </div>
      </div>
      <div class="flexL">
        <p class="bold">데이터 수집현황</p>
        <div class="flex">
          <p>환경</p>
          <el-switch v-model="environment"> </el-switch>
        </div>
        <div class="flex">
          <p>관수(양액)</p>
          <el-switch v-model="irrigation"> </el-switch>
        </div>
        <div class="flex">
          <p>생육</p>
          <el-switch v-model="growh"> </el-switch>
        </div>
        <div class="flex">
          <p>생산량</p>
          <input type="text" v-model="output" />
        </div>
      </div>
      <div class="flexL">
        <p class="bold">영농비용</p>
        <div class="flex">
          <p>농약비</p>
          <input type="text" v-model="pesticideCost" />
        </div>
        <div class="flex">
          <p>광열동력비</p>
          <input type="text" v-model="photothermalCost" />
        </div>
        <div class="flex">
          <p>기타(컨설팅 등)</p>
          <input type="text" v-model="etcCost" />
        </div>
        <div class="flex">
          <p>위탁영농비</p>
          <input type="text" v-model="consignmentCost" />
        </div>
        <div class="flex">
          <p>고용노력비</p>
          <input type="text" v-model="employCost" />
        </div>
        <div class="flex">
          <p>경영비</p>
          <input type="text" v-model="managementCost" />
        </div>
        <div class="flex">
          <p>수확</p>
          <input type="text" v-model="income" />
        </div>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="submit">수정</button>
        <button class="line" @click="handleDelete">삭제</button>
        <!-- <button v-if="!dropedAt" class="line" @click="handleWithdraw">강제탈퇴</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchFarmDetails, removeFarm, updateFarm } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      farmId: "",
      farmer: "",
      farmName: "",
      address: "",
      ages: "",
      career: "",
      area: "",
      crops: "",

      os: "",
      monitorSize: "",
      envrnCtrlProgram: "",
      pcLocation: "",

      houseType: [],
      houseDirection: "",
      houseCover: "",
      houseHeating: "",
      houseIrrigation: "",
      housePipeLength: "",
      houseHeight: "",
      houseWidth: "",
      houseLength: "",
      houseInsulation: "",
      houseVentilation: "",
      mainFarm: false,
      ups: false,
      internet: false,
      outsideTempSensor: false,
      windDirectionSensor: false,
      windSpeedSensor: false,
      insolationSensor: false,
      rainfallSensor: false,
      insideTempSensor: false,
      humiditySensor: false,
      co2Sensor: false,
      lightSensor: false,
      soilTempSensor: false,
      ceilingWndw: false,
      dplcWndw: false,
      sideWallWndw: false,
      cellarsHeatCurtain: false,
      wallHeatCurtain: false,
      shadeCurtain: false,
      heatMhrls: false,
      co2Supply: false,
      lightControl: false,
      dynmcFan: false,
      irrnFrtlzrSys: false,
      nutrientSlutnSys: false,
      sprinkler: false,
      fumigator: false,
      ctvtTY: "",
      spcies: "",
      seedling: "",
      soilType: "",
      compost: "",
      ptngDt: "",
      hvstBgnde: "",
      hvstEndde: "",
      dbyhsCccrrncDgree: "",
      dbyhsPrvnbeRtrvl: "",
      nutrientPH: "",
      nutrientEC: "",
      mdimPH: "",
      mdimEC: "",

      environment: false,
      irrigation: false,
      growh: false,
      output: "",
      hvstDay: "",

      pesticideCost: "",
      photothermalCost: "",
      etcCost: "",
      consignmentCost: "",
      employCost: "",
      managementCost: "",
      income: "",
      createdAt: "",
      updatedAt: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  mounted() {
    this.farmId = this.$route.query.id;
    this.getFarmDetail();
  },

  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeFarm(this.farmId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "농장 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.go(-1);
          }
        });
      });
    },
    submit() {
      if (this.farmName == "") {
        this.openAlert("농장 명칭을 입력해 주세요.");
        return;
      } else if (this.address == "") {
        this.openAlert("주소를 입력해 주세요.");
        return;
      } else if (this.crops == "") {
        this.openAlert("재배품목을 선택해 주세요.");
        return;
      } else if (this.area == "") {
        this.openAlert("재배면적을 입력해 주세요.");
        return;
      } else if (this.hvstDay == null) {
        this.openAlert("수확일자를 선택해 주세요.");
        return;
      }
      let data = {
        farmId: this.farmId,
        farmName: this.farmName,
        address: this.address,
        ages: this.ages,
        career: this.career,
        area: this.area,
        crops: this.crops,
        os: this.os,
        monitorSize: this.monitorSize,
        envrnCtrlProgram: this.envrnCtrlProgram,
        pcLocation: this.pcLocation,
        houseType: this.houseType,
        houseDirection: this.houseDirection,
        houseCover: this.houseCover,
        houseHeating: this.houseHeating,
        houseIrrigation: this.houseIrrigation,
        housePipeLength: this.housePipeLength,
        houseHeight: this.houseHeight,
        houseWidth: this.houseWidth,
        houseLength: this.houseLength,
        houseInsulation: this.houseInsulation,
        houseVentilation: this.houseVentilation,
        mainFarm: this.mainFarm,
        ups: this.ups,
        internet: this.internet,
        outsideTempSensor: this.outsideTempSensor,
        windDirectionSensor: this.windDirectionSensor,
        windSpeedSensor: this.windSpeedSensor,
        insolationSensor: this.insolationSensor,
        rainfallSensor: this.rainfallSensor,
        insideTempSensor: this.insideTempSensor,
        humiditySensor: this.humiditySensor,
        co2Sensor: this.co2Sensor,
        lightSensor: this.lightSensor,
        soilTempSensor: this.soilTempSensor,
        ceilingWndw: this.ceilingWndw,
        dplcWndw: this.dplcWndw,
        sideWallWndw: this.sideWallWndw,
        cellarsHeatCurtain: this.cellarsHeatCurtain,
        wallHeatCurtain: this.wallHeatCurtain,
        shadeCurtain: this.shadeCurtain,
        heatMhrls: this.heatMhrls,
        co2Supply: this.co2Supply,
        lightControl: this.lightControl,
        dynmcFan: this.dynmcFan,
        irrnFrtlzrSys: this.irrnFrtlzrSys,
        nutrientSlutnSys: this.nutrientSlutnSys,
        sprinkler: this.sprinkler,
        fumigator: this.fumigator,
        ctvtTY: this.ctvtTY,
        spcies: this.spcies,
        seedling: this.seedling,
        soilType: this.soilType,
        compost: this.compost,
        ptngDt: this.ptngDt,
        hvstBgnde: this.hvstBgnde,
        hvstEndde: this.hvstEndde,
        dbyhsCccrrncDgree: this.dbyhsCccrrncDgree,
        dbyhsPrvnbeRtrvl: this.dbyhsPrvnbeRtrvl,
        nutrientPH: this.nutrientPH,
        nutrientEC: this.nutrientEC,
        mdimPH: this.mdimPH,
        mdimEC: this.mdimEC,
        environment: this.environment,
        irrigation: this.irrigation,
        growh: this.growh,
        output: this.output,
        hvstDay: this.hvstDay,
        pesticideCost: this.pesticideCost,
        photothermalCost: this.photothermalCost,
        etcCost: this.etcCost,
        consignmentCost: this.consignmentCost,
        employCost: this.employCost,
        managementCost: this.managementCost,
        income: this.income,
      };
      updateFarm(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "농장 정보가 수정 되었습니다.",
            type: "success",
          });
          this.$router.go(-1);
        }
      });
    },
    getFarmDetail() {
      fetchFarmDetails(this.farmId).then((res) => {
        if (res.data.status == 200) {
          this.farmer = res.data.data.farmer;
          this.farmName = res.data.data.farmName;
          this.address = res.data.data.address;
          this.ages = res.data.data.ages;
          this.career = res.data.data.career;
          this.area = res.data.data.area;
          this.crops = res.data.data.crops;
          this.os = res.data.data.os;
          this.monitorSize = res.data.data.monitorSize;
          this.envrnCtrlProgram = res.data.data.envrnCtrlProgram;
          this.pcLocation = res.data.data.pcLocation;
          this.houseType = res.data.data.houseType;
          this.houseDirection = res.data.data.houseDirection;
          this.houseCover = res.data.data.houseCover;
          this.houseHeating = res.data.data.houseHeating;
          this.houseIrrigation = res.data.data.houseIrrigation;
          this.housePipeLength = res.data.data.housePipeLength;
          this.houseHeight = res.data.data.houseHeight;
          this.houseWidth = res.data.data.houseWidth;
          this.houseLength = res.data.data.houseLength;
          this.houseInsulation = res.data.data.houseInsulation;
          this.houseVentilation = res.data.data.houseVentilation;
          this.mainFarm = res.data.data.mainFarm;
          this.ups = res.data.data.ups;
          this.internet = res.data.data.internet;
          this.outsideTempSensor = res.data.data.outsideTempSensor;
          this.windDirectionSensor = res.data.data.windDirectionSensor;
          this.windSpeedSensor = res.data.data.windSpeedSensor;
          this.insolationSensor = res.data.data.insolationSensor;
          this.rainfallSensor = res.data.data.rainfallSensor;
          this.insideTempSensor = res.data.data.insideTempSensor;
          this.humiditySensor = res.data.data.humiditySensor;
          this.co2Sensor = res.data.data.co2Sensor;
          this.lightSensor = res.data.data.lightSensor;
          this.soilTempSensor = res.data.data.soilTempSensor;
          this.ceilingWndw = res.data.data.ceilingWndw;
          this.dplcWndw = res.data.data.dplcWndw;
          this.sideWallWndw = res.data.data.sideWallWndw;
          this.cellarsHeatCurtain = res.data.data.cellarsHeatCurtain;
          this.wallHeatCurtain = res.data.data.wallHeatCurtain;
          this.shadeCurtain = res.data.data.shadeCurtain;
          this.heatMhrls = res.data.data.heatMhrls;
          this.co2Supply = res.data.data.co2Supply;
          this.lightControl = res.data.data.lightControl;
          this.dynmcFan = res.data.data.dynmcFan;
          this.irrnFrtlzrSys = res.data.data.irrnFrtlzrSys;
          this.nutrientSlutnSys = res.data.data.nutrientSlutnSys;
          this.sprinkler = res.data.data.sprinkler;
          this.fumigator = res.data.data.fumigator;
          this.ctvtTY = res.data.data.ctvtTY;
          this.spcies = res.data.data.spcies;
          this.seedling = res.data.data.seedling;
          this.soilType = res.data.data.soilType;
          this.compost = res.data.data.compost;
          this.ptngDt = res.data.data.ptngDt;
          this.hvstBgnde = res.data.data.hvstBgnde;
          this.hvstEndde = res.data.data.hvstEndde;
          this.dbyhsCccrrncDgree = res.data.data.dbyhsCccrrncDgree;
          this.dbyhsPrvnbeRtrvl = res.data.data.dbyhsPrvnbeRtrvl;
          this.nutrientPH = res.data.data.nutrientPH;
          this.nutrientEC = res.data.data.nutrientEC;
          this.mdimPH = res.data.data.mdimPH;
          this.mdimEC = res.data.data.mdimEC;
          this.environment = res.data.data.environment;
          this.irrigation = res.data.data.irrigation;
          this.growh = res.data.data.growh;
          this.output = res.data.data.output;
          this.hvstDay = res.data.data.hvstDay;
          this.pesticideCost = res.data.data.pesticideCost;
          this.photothermalCost = res.data.data.photothermalCost;
          this.etcCost = res.data.data.etcCost;
          this.consignmentCost = res.data.data.consignmentCost;
          this.employCost = res.data.data.employCost;
          this.managementCost = res.data.data.managementCost;
          this.income = res.data.data.income;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
        }
      });
    },
  },
};
</script>
